import * as React from 'react'
import {
  Checks,
  CircleWavyCheck,
  Megaphone,
  Notebook,
} from '@phosphor-icons/react'
import { Tag } from 'src/design-system'
import { UpdateCounts, UpdateType } from '../../types/updates'

export type UpdateHoverCardProps = {
  additionalInformation?: React.ReactNode
  includedUpdateTypes?: UpdateType[]
  updateCounts: UpdateCounts
}

export const UpdateHoverCard = (props: UpdateHoverCardProps) => {
  const {
    additionalInformation,
    includedUpdateTypes,
    updateCounts: {
      actionsCompletedCount = 0,
      feedbackReceivedCount = 0,
      notesCount = 0,
      winsReceivedCount = 0,
    },
  } = props

  const showUpdate = (updateType: UpdateType) => {
    // show all update types if there is no filtering in place
    if (!includedUpdateTypes || includedUpdateTypes.length === 0) return true

    return includedUpdateTypes?.includes(updateType)
  }

  return (
    <div className="whitespace-nowrap w-auto rounded border text-gray-600 border-gray-100 p-3 bg-white flex flex-col gap-y-1 shadow-modal text-sm">
      {showUpdate('wins') && (
        <div className="flex flex-row items-center gap-x-2">
          <CircleWavyCheck
            className="w-3.5 h-3.5 text-green-400"
            weight="bold"
          />
          Wins received
          <Tag variant="paper" className="ml-auto py-0.5">
            {winsReceivedCount}
          </Tag>
        </div>
      )}
      {showUpdate('actions') && (
        <div className="flex flex-row items-center gap-x-2">
          <Checks className="w-3.5 h-3.5 text-green-600" weight="bold" />
          Actions completed
          <Tag variant="paper" className="ml-auto py-0.5">
            {actionsCompletedCount}
          </Tag>
        </div>
      )}
      {showUpdate('feedback_received') && (
        <div className="flex flex-row items-center gap-x-2">
          <Megaphone className="w-3.5 h-3.5 text-yellow-600" weight="bold" />
          Feedback received
          <Tag variant="paper" className="ml-auto py-0.5">
            {feedbackReceivedCount}
          </Tag>
        </div>
      )}
      {showUpdate('notes') && (
        <div className="flex flex-row items-center gap-x-2">
          <Notebook className="w-4 h-4 text-blue-800" weight="bold" />
          Notes
          <Tag variant="paper" className="ml-auto py-0.5">
            {notesCount}
          </Tag>
        </div>
      )}
      {additionalInformation}
    </div>
  )
}
